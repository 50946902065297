<template>
  <div>
    <XeInputDropdown
      id="companycountry"
      v-model="inputValue"
      :dropdown-label="dropdownLabel"
      option-label="name"
      option-value="code"
      placeholder="Select currency"
      :disabled="disabled"
      :options="countries"
      :validation-error-message="validationErrorMessage"
      @input="onCountrySet($event)"
    >
      <template #custom-value-placeholder="{ selectedValue, selectedLabel }">
        <div v-if="isOnlyCurrencyNameOption">
          <span class="country-name-after-icon">{{ selectedValue }}</span>
        </div>
        <div v-else class="country-item">
          <AppFlag class="country-flag">
            <Component :is="getFlag(selectedValue)"></Component>
          </AppFlag>
          <span class="country-name-after-icon">{{ selectedLabel }}</span>
        </div>
      </template>

      <template #custom-option="{ option }">
        <div v-if="isOnlyCurrencyNameOption">{{ option.code }}</div>
        <div v-else class="country-item">
          <AppFlag class="country-flag">
            <Component :is="getFlag(option.code)"></Component>
          </AppFlag>
          <span class="country-name-after-icon">{{ option.name }}</span>
        </div>
      </template>
    </XeInputDropdown>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import AppFlag from '@en-ui/components/AppFlag/AppFlag'
import XeInputDropdown from '@/components/XeInputDropdown/XeInputDropdown'
import * as EuronetFlags from '@moneytransfer.ui/euronet-flags'
import { useStore } from '@/composables/useStore'
import { isInCountryGroup } from '@/utils/i18n'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'

export default {
  name: 'XeInputCurrency',
  components: {
    AppIcon,
    AppFlag,
    XeInputDropdown,
  },
  emit: ['input'],
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdownLabel: {
      type: String,
      default: '',
    },
    validationErrorMessage: {
      type: String,
      default: '',
    },
    countries: {
      type: Array,
      default: null,
      required: true,
    },
    isOnlyCurrencyNameOption: {
      type: Boolean,
      default: false,
    },
  },
  setup($props, { emit }) {
    const companyDetailsStore = useCompanyDetailsStore()
    const inputValue = ref('')

    const getFlagIsoCodeFromCurrencyIsoCode = (currencyIsoCode) => {
      let flagIsoCode = currencyIsoCode.substring(0, 2)
      if (flagIsoCode === 'XA') {
        flagIsoCode = 'CD'
      } else if (flagIsoCode === 'XC') {
        flagIsoCode = 'LC'
      } else if (flagIsoCode === 'XO') {
        flagIsoCode = 'CI'
      }
      return flagIsoCode.toUpperCase() || null
    }

    const getFlag = (flagCode) => {
      let flag = null
      const flagIsoCode = getFlagIsoCodeFromCurrencyIsoCode(flagCode)
      if (flagCode) {
        flag = 'Flag' + flagIsoCode
      }
      if (flag === 'FlagKV') {
        flag = 'FlagCV'
      }
      return EuronetFlags[flag] || null
    }

    const prefillCurrencyByCountry = () => {
      const corporateRegistrationStore = useCorporateRegistrationStore()

      const getCountry = companyDetailsStore.companyCountry
      const getCurrencyTypeList = corporateRegistrationStore.currencyTypeList
      let getPrefilledCurrency =
        getCurrencyTypeList.find((currency) => currency.code.substring(0, 2) === getCountry)
          ?.code || ''
      if (getCountry === 'CK') {
        getPrefilledCurrency = 'NZD'
      } else if (getCountry === 'NF') {
        getPrefilledCurrency = 'AUD'
      }
      if (isInCountryGroup('EU', getCountry) && getCountry !== 'GB') {
        return emit('input', 'EUR')
      }
      return emit('input', getPrefilledCurrency)
    }

    prefillCurrencyByCountry()

    const onCountrySet = async (country) => {
      emit('input', country)
    }

    // When component has a value, set input values
    const updateInputValues = () => {
      inputValue.value = $props.value
    }

    // Update input values if component has initial value
    if ($props.value.length > 0) {
      updateInputValues()
    }
    // Update input values if props.value updated not from component
    watch(() => $props.value, updateInputValues)

    return {
      inputValue,
      getFlag,
      onCountrySet,
    }
  },
}
</script>

<style lang="postcss" scoped>
.country-item {
  @apply flex flex-row items-center;

  .country-name-after-icon {
    @apply ml-2;
  }
}
</style>
